import { ChangeEvent, MouseEvent, useContext, useState } from 'react';
import MUITableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { TableActions, ColParam } from '../table.models';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FiMoreHorizontal } from 'react-icons/fi';
import PatientContext from 'src/core/context/patient/PatientContext';
import { useTranslation } from 'react-i18next';
import { colors } from 'src/assets/styles/theme.style';

interface Props {
  rowCount: number;
  selectedRows?: any[];
  columns: ColParam[];
  actions?: TableActions;
  order: 'asc' | 'desc';
  orderBy: string;
  multiSelect: boolean;
  showMenuItem?: boolean;
  onRequestSort: (event: MouseEvent<unknown>, property: string, order: string) => void;
  onSelectAllRows: (event: ChangeEvent<HTMLInputElement>) => void;
  isDropdownAllowed?: boolean;
}

function TableHead(props: Props) {
  const { t } = useTranslation();
  const [anchorGroupMenu, setAnchorGroupMenu] = useState<null | HTMLElement>(null);
  const openGroupMenu = Boolean(anchorGroupMenu);

  const createSortHandler = (property: string, order: string) => (event: MouseEvent<unknown>) => {
    props.onRequestSort(event, property, order);
  };

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorGroupMenu(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setAnchorGroupMenu(null);
  };

  return (
    <MUITableHead>
      <TableRow>
        {props.multiSelect && props.selectedRows && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={props.selectedRows.length > 0 && props.selectedRows.length < props.rowCount}
              checked={props.rowCount > 0 && props.selectedRows.length === props.rowCount}
              onChange={props.onSelectAllRows}
              inputProps={{
                'aria-label': 'select all rows',
              }}
            />
          </TableCell>
        )}
        {props.columns.map((colParam, index) => (
          <TableCell
            key={colParam.key}
            align={colParam.align || 'left'}
            padding={colParam.disablePadding ? 'none' : 'normal'}
            sortDirection={props.orderBy === colParam.key ? props.order : false}
          >
            <TableSortLabel
              active={props.orderBy === colParam.key}
              direction={props.orderBy === colParam.key ? props.order : 'asc'}
              onClick={createSortHandler(colParam.key, props.order)}
              style={{ color: colors.tableHeaderText }}
              className='custom-sort-label'
            >
              {colParam.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {props.isDropdownAllowed && (
          <TableCell align={'left'} padding={'normal'}>
            <TableSortLabel>{t('userManagement.systemRole')}</TableSortLabel>
          </TableCell>
        )}
        {props.actions && (
          <TableCell key={'props.actions'} align={'right'} padding={'normal'}>
            {!props.showMenuItem && props.actions.groupActions && (
              <>
                <Button
                  color='success'
                  className='action-button'
                  variant='contained'
                  size='small'
                  disabled={props.selectedRows && props.selectedRows.length < 2}
                  disableElevation
                  aria-controls={openGroupMenu ? 'group-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={openGroupMenu ? 'true' : undefined}
                  onClick={handleOpenActionMenu}
                >
                  <FiMoreHorizontal />
                </Button>
                <Menu
                  id='group-menu'
                  className='action-menu'
                  anchorEl={anchorGroupMenu}
                  open={openGroupMenu}
                  onClose={handleCloseActionMenu}
                  onClick={handleCloseActionMenu}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {props.actions.groupActions.map((action, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        action.onClick(props.selectedRows ? props.selectedRows : []);
                      }}
                    >
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </TableCell>
        )}
      </TableRow>
    </MUITableHead>
  );
}

export default TableHead;
